<template>
  <div>   
    <!--<div class="d-flex flex-column align-center bg-grey-lighten-4 pa-6">
    <v-btn-toggle
      v-model="toggle"
      color="primary"
      
    >
      <v-btn value="visits" @click="convertToVisist()">زيارات</v-btn>
      <v-btn  value="NotVisits" >مبيعات</v-btn>
    </v-btn-toggle>
    <pre class="pt-2">{{ toggle }}</pre>
  </div>  -->
       
    <div v-if="loading == true" class="container">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div v-if="loading == false">
      <div class="d-flex" >
        <b-form-datepicker id="example-datepicker" v-model="date" class="m-3" @input="logValue"></b-form-datepicker>        
        <User class="m-3" />
      </div>
      <div class="d-flex" >
        <b-button v-if="idIsadmin != 0" class="m-3 align-items-center btn_bootstrap" @click="convertToVisist()">المبيعات</b-button>
      </div>
      <div class="px-4 mb-2">
        <v-chip-group v-model="selection" selected-class="text-deep-purple-accent-4">
          <v-chip :color="chip == -1 ? 'orange' : ''" @click="editDate(-1)">أمس</v-chip>

          <v-chip :color="chip == 0 ? 'orange' : ''" @click="editDate(0)">اليوم</v-chip>

          <v-chip :color="chip == 1 ? 'orange' : ''" @click="editDate(1)">الغد</v-chip>

          <v-chip :color="chip == 2 ? 'orange' : ''" @click="editDate(2)">بعد الغد</v-chip>
        </v-chip-group>
      </div>
      <h1 v-if=" loading == false && products.length == 0" class="m-4"> لا يوجد جلسات مبيعات عند التاريخ المحدد ....</h1>
      <template>
        <v-card :disabled="loading" :loading="loading" class="mx-auto my-12" max-width="374">
          <template v-slot:loader="{ isActive }">
            <v-progress-linear :active="isActive" color="deep-purple" height="4" indeterminate></v-progress-linear>
          </template>

          <b-modal id="bv-modal-example" hide-footer>
            <template #modal-title>
              <div class="text-center">تأكيد بداية الجلسة</div>
            </template>
            <div class="d-block text-center">
              <h3>هل أنت متأكد ابتداء الجلسة</h3>
            </div>
            <b-button class="mt-3" block
              @click="$bvModal.hide('bv-modal-example'); editStartSession(id);">تأكيد</b-button>
          </b-modal>
          <b-modal id="bv-modal-example2" hide-footer>
            <template #modal-title>
              <div class="text-center">تأكيد انتهاء الجلسة</div>
            </template>
            <div class="d-block text-center">
              <h3>هل أنت متأكد من انتهاء الجلسة</h3>
            </div>
            <b-button class="mt-3" block
              @click="$bvModal.hide('bv-modal-example2'); editFinishSession(id);">تأكيد</b-button>
          </b-modal>

          <b-modal id="bv-modal-CancelSession" hide-footer>
            <template #modal-title>
              <div class="text-center">تأكيد إلغاء الجلسة</div>
            </template>
            <div class="d-block text-center">
              <h3>هل أنت متأكد من إلغاء الجلسة</h3>
            </div>
            <b-button class="mt-3" block
              @click="$bvModal.hide('bv-modal-CancelSession'); editFinishSession(id);">تأكيد</b-button>
          </b-modal>

        </v-card>
      </template>
      <div>

        <div class="card1" v-for="(item, i) in products" :key="i">
          <template>
            <v-card class="mx-auto anim" max-width="344">              
                <v-card-title >
                  <div class="wr">
                  {{ item.ClientName +' / ' + item.Governorate}} <br> {{ item.Region }}                                   
                </div>              
              </v-card-title>               
              <v-divider></v-divider>  
              <v-card-subtitle class="mr-3 font-weight-bold">
                {{' الموظف :  '+item.DisplayName }}      
              </v-card-subtitle>              
              <v-card-subtitle v-if="item.Numberphone != null" class="telephone font-weight-bold mr-3">
                &nbsp;&nbsp;&nbsp;<a v-bind:href="'tel:'+ item.Numberphone"><svg-icon type="mdi" :path="pathPhone"></svg-icon></a> &nbsp;&nbsp;&nbsp; <a class="text-md-center" v-bind:href="'https://wa.me/'+ item.Numberphone"><svg-icon type="mdi" :path="pathW"></svg-icon></a> {{ item.Numberphone+ '  '  }}
              </v-card-subtitle >
              <v-btn v-if="item.latitude == 0 " @click="takeLocation(item.Id)" class="col-5 mr-3"  > <svg-icon color="red" type="mdi" :path="pathmdiCloseCircleOutline"></svg-icon>تحديد الموقع</v-btn>
              <v-btn v-else class="col-5 mr-3"  > <svg-icon color="green" type="mdi" :path="pathmdiCheckboxMarkedCircleOutline"></svg-icon>تحديد الموقع</v-btn>
              <v-card-subtitle class="mr-3 font-weight-bold">
                {{ 'هل الشركة موجودة ؟' }}<br>
                {{ 'نعم' }}<input type="radio" id="one" :value=false v-model="item.IsClosed" />&nbsp;&nbsp;&nbsp;
                {{ 'كلا' }}<input type="radio" id="one" :value=true v-model="item.IsClosed" />                
              </v-card-subtitle>    
              <v-card-subtitle v-if="item.IsClosed == false" class="mr-3 font-weight-bold">
                {{ 'هل ما زال يستخدم برامج الشركة ؟' }}<br>
                {{ 'نعم' }}<input type="radio" id="one" :value=true v-model="item.IsUsedPrograming" />&nbsp;&nbsp;&nbsp;
                {{ 'كلا' }}<input type="radio" id="one" :value=false v-model="item.IsUsedPrograming" />                
              </v-card-subtitle>   
              <v-card-subtitle v-if="item.clientPrograms.length>0 && item.IsClosed == false && item.IsUsedPrograming == true" class="mr-3 font-weight-bold">                                                              
                <!--
                {{ 'ماهي البرامج والمعدات المستخدمة في الشركة ؟' }}<br><br>     
                <v-select  outlined label="البرنامج" :items="item.clientPrograms" name="program" item-value="ProgramId"  
                  item-text="ProgramName" multiple  ></v-select>                   -->
                  {{ 'مدى رضى الزبون عن أداء برامجه ؟' }}<br>
                  <div v-for="(item2, i2) in item.clientPrograms" :key="i2" >{{item2.ProgramName}}
                    <v-rating
                      v-model="item2.CustomerSatisfaction"
                      active-color="blue"
                      color="orange"
                    ></v-rating>
                  </div>
                  <v-textarea v-if="item.clientPrograms.length>0 && item.IsClosed == false" class="ml-3 mr-3 pa-0" outlined label="التفاصيل" name="details" v-model="item.Details"
                  :rules="[$rules.required()]" width ="500px"></v-textarea>
                  <!--<Select2 v-model="item.ProgramId" :options="items"  :settings="{ width: '100%' }" /><br>-->
              </v-card-subtitle>                
              <div class="text-center">
                <!--<v-btn v-if="chip==0 && item.Details != ''" class="col-5 mb-2" color="primary" >تم</v-btn>-->
                <v-btn @click="updateVisitSalesmen(item)" class="col-5 mb-2" color="primary" >تم</v-btn>
              </div>    
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js';
import User from "../../layouts/User.vue";
import { mdiCheckboxMarkedCircleOutline ,mdiCloseCircleOutline , mdiWhatsapp,mdiPhone} from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';

//import DraggableTable from "../components/DraggableTable.vue";
export default {
  computed: {
    numberErrors() {
      const errors = [];
      if(!this.comboBoxModel){
        return errors;
      }
      if(this.programs.some(item => item.Name == this.comboBoxModel)){
        return errors;
      }
      errors.push('Invalid number.');

      return errors;
    }
  },
  components: {
    User,
    SvgIcon
  },
  data() {
    return {
      pathmdiCheckboxMarkedCircleOutline:mdiCheckboxMarkedCircleOutline,
      pathmdiCloseCircleOutline:mdiCloseCircleOutline,
      pathPhone:mdiPhone,
      pathW:mdiWhatsapp,
      idIsadmin : 0,
      toggle:"NotVisits",
      products:[],
      loading:false,
      date: new Date(),
      chip :0,
      IsClosed:false,
      picked:false,
      programs:[],
      admin:false,
      ExistVisits:false
    }
  },
  async mounted() {
    this.getnews();
  },
  async created() {
    this.getnews();
  },
  methods: {  
    convertToVisist(){      
      this.$router.push({ name: "visits" });
      localStorage.setItem("page","visit");
    },
    takeLocation(id){
      const success =  async(position) => {
        const latitude  = position.coords.latitude;
            const longitude = position.coords.longitude;            
            await this.$http.post("/detectLocation",{
              Id:id,
              latitude : latitude,
              longitude : longitude
            }).then(async (res) => {             
              if (res.status == 200) {       
                await this.getnews();                                                     
              } 
            })
      }      
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success);
      }
    },
    async updateVisitSalesmen(item){    
      for (let j = 0; j < item.clientPrograms.length; j++) {
        this.loading = true;
      await this.$http.post("/EditRatingClientProgram",{
            Id:item.clientPrograms[j].Id,
            CustomerSatisfaction:item.clientPrograms[j].CustomerSatisfaction,
          })          
      }    
      await this.$http.post("/EditDetailsVisitSalesmen",{
            ClientId:item.ClientId,
            IsUsedPrograming:item.IsUsedPrograming,
            Details:item.Details,
            Id:item.Id,
            IsClosed:item.IsClosed
          })   
      this.$root.$emit("refresh-data");
          this.loading = false; 
    }, 
      onAutoCompleteSelection(){
        this.comboBoxModel = this.comboBoxDummyModel;
        this.onChangeHandler();
      },
    customOnChangeHandler(){
      let vm = this;
      setTimeout(function(){
        if(vm.$refs.numberComboBox){
          vm.comboBoxModel = vm.$refs.numberComboBox.internalSearch;
          vm.onChangeHandler();
        }
      });
    },
    encrypteData(data) {
      if (data) {
        let key = CryptoJS.enc.Utf8.parse("edariworkmenkmen");
        const data_encrypted = CryptoJS.AES.encrypt(data, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString();
        //const iv = CryptoJS.enc.Utf8.parse("keyValue"); // Convert string to WordArray
        //const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC });
        return data_encrypted;//" encrypted.ciphertext.toString(CryptoJS.enc.Hex);
      }
    },
    EditId(id, appointment, IsLate, startSession) {
      this.id = id;
      this.appointment = appointment;
      this.IsLate = IsLate;
      this.startSession = startSession;
    },
    showitem(i) {
      return this.showDetails[i];
    },
    
    editDate(d) {
      this.chip = d;
      const date = new Date();
      date.setDate(date.getDate() + d)
      let day = date.getDate();
      let month = date.getMonth() + 1;
      if (month < 10)
        month = '0' + month;
      if (day < 10)
        day = '0' + day;
      let year = date.getFullYear();
      this.date = `${year}-${month}-${day}`;
      this.logValue();
    },
    editShow(i) {
      this.showDetails[i] = !this.showDetails[i];
    },
    async logValue() {
      this.loading = true;
      var date = new Date();
      date.setDate(date.getDate() + 0)
      let day = date.getDate();
      let month = date.getMonth() + 1;
      if (month < 10)
        month = '0' + month;
      if (day < 10)
        day = '0' + day;
      let year = date.getFullYear();

      if (this.date == `${year}-${month}-${day}`)
        this.chip = 0;
      else {
        date = new Date();
        date.setDate(date.getDate() - 1)
        day = date.getDate();
        month = date.getMonth() + 1;
        if (month < 10)
          month = '0' + month;
        if (day < 10)
          day = '0' + day;
        year = date.getFullYear();
        if (this.date == `${year}-${month}-${day}`)
          this.chip = -1;
        else {
          date = new Date();
          date.setDate(date.getDate() + 1)
          day = date.getDate();
          month = date.getMonth() + 1;
          if (month < 10)
            month = '0' + month;
          if (day < 10)
            day = '0' + day;
          year = date.getFullYear();
          if (this.date == `${year}-${month}-${day}`)
            this.chip = 1;
          else {
            date = new Date();
            date.setDate(date.getDate() + 2)
            day = date.getDate();
            month = date.getMonth() + 1;
            if (month < 10)
              month = '0' + month;
            if (day < 10)
              day = '0' + day;
            year = date.getFullYear();
            if (this.date == `${year}-${month}-${day}`)
              this.chip = 2;
            else
              this.chip = 5;
          }
        }
      }
      this.$store.commit("setLoading", true);
      //this.showDetails = [];
      //this.array_details = [];
      var t = new Date();
      t = t.getTime().toString();
      this.idIsadmin = localStorage.getItem("userData");
      await this.$http.get("/salesmen", {
        params: {
          date: this.encrypteData(this.date),
          id: this.encrypteData(localStorage.getItem("userData")),
          tocken: this.encrypteData(t)
        }
      })
        .then((res) => {
          if (res.status == 200) {
            this.products = res.data.result;
            this.admin = res.data.admin;
            //this.products = res.data.recordsets[0];
            if (this.products.length == 0)
              this.ExistVisits = true;
            else
              this.ExistVisits = false;            
            this.error = false;
            this.$store.commit("setLoading", false);
          } else {
            this.error = true;
            this.$store.commit("setLoading", false);
          }
        })
      this.loading = false;
    },
    async getnews() {
      if(localStorage.getItem("page") == "visit"){
        this.$router.push({ name: "visits" });
        return;
      }          
      this.loading = true;
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      if (month < 10)
        month = '0' + month;
      if (day < 10)
        day = '0' + day;
      let year = date.getFullYear();
      let dT = `${year}-${month}-${day}`;
      this.dateToday = dT;
      this.$store.commit("setLoading", true);
      this.showDetails = [];
      var t = new Date();
      t = t.getTime().toString();
      this.idIsadmin = localStorage.getItem("userData");
      await this.$http.get("/salesmen", {
        params: {
          date: this.encrypteData(this.dateToday),
          id: this.encrypteData(localStorage.getItem("userData")),
          tocken: this.encrypteData(t)
        }
      })
        .then((res) => {
          if (res.status == 200) {
            this.chip = 0;
            this.total = 410;
            this.products = res.data.result;
          //  this.admin = res.data.admin;            
            this.error = false;
            this.$store.commit("setLoading", false);
          } else {
            this.error = true;
            this.$store.commit("setLoading", false);
          }
        })  
        
        await this.$http.get("/programs", {
        params: {
          date: this.encrypteData(this.dateToday),
          id: this.encrypteData(localStorage.getItem("userData")),
          tocken: this.encrypteData(t)
        }
      })
        .then((res) => {
          if (res.status == 200) {
            this.chip = 0;
            this.total = 410;
            this.programs = res.data.result;     
            this.items = [];
            this.programs.forEach(element => {
              this.items.push(element.Name)
            });  
            this.error = false;
            this.$store.commit("setLoading", false);
          } else {
            this.error = true;
            this.$store.commit("setLoading", false);
          }
        })   
      this.loading = false;
    },
  },
  watch: {
    options: {
      handler() {
        this.getnews();
      },
      deep: true,
    },
  },

};

</script>
<style scoped>
.telephone{
  direction: ltr;
  text-align: right;
}
.wr{
  word-break: auto-phrase !important;
}
:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --green: #2b913c;
}
.btn_bootstrap{
  background-color: #187DA0;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 5px;
}

.v-card__title {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  justify-content: space-between;
}

.btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.card1 {
  margin: 10px;
}

.data-picker {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.b_btn {
  margin-left: 10px;
  margin-right: 10px;
}

.telephone {
  direction: ltr;
  text-align: right;
}

.v-application .my-12 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.lds-roller {
  /* change color here */
  color: #1c4c5b
}

.lds-roller,
.lds-roller div,
.lds-roller div:after {
  box-sizing: border-box;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7.2px;
  height: 7.2px;
  border-radius: 50%;
  background: currentColor;
  margin: -3.6px 0 0 -3.6px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 62.62742px;
  left: 62.62742px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 67.71281px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 70.90963px;
  left: 48.28221px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 70.90963px;
  left: 31.71779px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 67.71281px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 62.62742px;
  left: 17.37258px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12.28719px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #050801;
  font-family: 'raleway', sans-serif;
  font-weight: bold;
}

.anim {
  /*color: #03e9f4;*/
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  overflow: hidden;
  margin-right: 50px;
}

.anim div span {
  position: absolute;
  display: block;
}

.anim div span:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #1e90ff);
  animation: animate1 2s linear infinite;
}

@keyframes animate1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.anim div span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #1e90ff);
  animation: animate2 2s linear infinite;
  animation-delay: 1s;
}

@keyframes animate2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}


.anim div span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #1e90ff);
  animation: animate3 2s linear infinite;
}

@keyframes animate3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

.anim div span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #1e90ff);
  animation: animate4 2s linear infinite;
  animation-delay: 1s;
}

@keyframes animate4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

.blink_me {
  animation: blinker 2s linear infinite;
}

label {
  color: green;
  display: block;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>